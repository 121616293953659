
<template>
  <Transition name="opacity">
    <slot class="will-change-[opacity,transform,backdrop-filter]" />
  </Transition>
</template>

<style lang="sass">
.opacity-enter-active, .opacity-leave-active
  transition: all 0.3s ease
  overflow: hidden

.opacity-enter-from, .opacity-leave-to
  opacity: 0

.opacity-enter-to, .opacity-leave-from
  opacity: 1
</style>
